import { Box, Button, Container, Grid, Typography } from "@mui/material";
import partner1 from "../Assests/partner1.jpg";
import partner2 from "../Assests/partner2.jpg";
import partner3 from "../Assests/partner3.jpg";
import vision from "../Assests/vision.svg";
import integrity from "../Assests/balance.svg";
import accountability from "../Assests/accountability.svg";
import discipline from "../Assests/discipline.svg";
import peoplematter from "../Assests/peoplematter.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  return (
    <>
      {/* Support */}
      <Container sx={{ mt: 5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: 24,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Why are We a TRUSTED Software Development Company?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 200,
              fontSize: 16,
              align: "center",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            At techHabiles, trust is the cornerstone of our reputation and
            success. We have earned the trust of our clients, partners, and
            stakeholders through our unwavering commitment to excellence,
            integrity, and customer satisfaction.
            <br /> <br />
            At techHabiles, trust is not just something we earn; it's something
            we value and uphold in everything we do. When you choose us as your
            software partner, you can trust that you are working with a company
            that is committed to your success and dedicated to delivering
            exceptional results.
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ marginTop: 5 }}>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item>
              <img
                src={partner1}
                alt="partner1"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Proven Track Record
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                With years of experience in the industry, we have established a
                track record of delivering high-quality software solutions that
                meet and exceed our clients' expectations. Our portfolio
                showcases a diverse range of successful projects across various
                industries, demonstrating our expertise and reliability.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item sx={{ paddingRight: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Transparent Communication
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                We believe in open and transparent communication with our
                clients at every stage of the project. From initial consultation
                to ongoing support, we keep our clients informed and involved,
                ensuring that their vision and objectives are fully understood
                and addressed.
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={partner2}
                alt="partner2"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item>
              <img
                src={partner3}
                alt="partner3"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Expert Team
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Our team consists of seasoned professionals who are passionate
                about what they do. With deep expertise in software development,
                design, and project management, we have the skills and knowledge
                to tackle even the most complex challenges and deliver
                exceptional results.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item sx={{ paddingRight: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Customer-Centric Approach
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                At the core of our company culture is a commitment to putting
                our customers first. We take the time to listen to their needs,
                understand their goals, and tailor our solutions to meet their
                specific requirements. Customer satisfaction is not just a goal
                but a priority in everything we do.
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={partner2}
                alt="partner2"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item>
              <img
                src={partner3}
                alt="partner3"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Quality Assurance
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                We adhere to rigorous quality assurance processes to ensure that
                our software meets the highest standards of performance,
                reliability, and security. Our dedication to quality is
                reflected in the robustness and efficiency of our products,
                giving our clients peace of mind knowing that they are investing
                in a solution they can trust.
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item sx={{ paddingRight: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Long-Term Relationships
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                We view our clients as partners and collaborators, and we are
                committed to building long-term relationships based on mutual
                trust and respect. We strive to be more than just a vendor; we
                aim to be a trusted advisor and ally in our clients' success.
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={partner2}
                alt="partner2"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 1, mb: 1 }}>
            <Grid item>
              <img
                src={partner3}
                alt="partner3"
                height={100}
                width={150}
                style={{ border: "1px solid #4D4D4D", borderRadius: 10 }}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: 5 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Ethical Practices
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4D4D4D",
                  fontSize: 15,
                  mt: 2,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Integrity is non-negotiable for us. We conduct our business with
                honesty, fairness, and integrity, adhering to the highest
                ethical standards in all our dealings. Our clients can trust
                that we will always act with their best interests in mind.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* Vision */}
      <Box
        sx={{
          backgroundImage: `url(${vision})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 5,
        }}
      >
        <Container
          sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}
        >
          <Typography
            variant="h5"
            align="center"
            color="#FAFAFA"
            marginTop="16px"
            sx={{ fontWeight: 600, fontFamily: "Poppins, sans-serif" }}
          >
            Our Vision
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="#FAFAFA"
            sx={{
              fontSize: 14,
              mt: 2,
              mb: 2,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            At Techhabiles, we envision a future where technology is seamlessly
            integrated into every aspect of life, enriching experiences, and
            driving positive change. Our vision is to be the leading force in
            shaping this future, by creating software solutions that inspire
            innovation, empower individuals, and transform industries. <br />
            <br />
            We see a world where our software transcends boundaries, connecting
            people and businesses across the globe. Our vision is to build
            bridges between different cultures and communities, fostering
            collaboration and driving collective progress. <br />
            <br />
            Driven by a passion for innovation and fueled by creativity, we
            envision software that anticipates needs, exceeds expectations, and
            enriches lives. Our goal is to create intuitive, user-centric
            solutions that enhance productivity, streamline processes, and
            unlock new opportunities for growth. <br />
            <br />
            Above all, our vision is underpinned by a commitment to integrity
            and social responsibility. We believe in harnessing the power of
            technology to address pressing challenges, promote equality, and
            create a more sustainable future for all. <br />
            <br />
            As we pursue our vision, we remain steadfast in our dedication to
            excellence, collaboration, and continuous improvement. Together, we
            strive to make our vision a reality, shaping a world where
            technology empowers individuals, fosters innovation, and drives
            positive change."{" "}
          </Typography>
          <Box>
            {" "}
            <Button
              onClick={() => navigate("/contactus")}
              sx={{
                backgroundColor: "#EDF4FF",
                fontFamily: "Poppins, sans-serif",
                color: "#025BCF",
                padding: 1,
                width: 200,
                border: "1px solid #025BCF",
                borderRadius: 20,
                textTransform: "unset",
                marginBottom: "16px",
                mt: 2,
                "&:hover": {
                  border: "1px solid #025BCF",
                  backgroundColor: "#EDF4FF",
                  color: "#025BCF",
                },
              }}
            >
              Contact Us <ChevronRightIcon fontSize="small" />
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Core Values */}
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: 28,
              color: "#4D4D4D",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Our Core Values
          </Typography>
        </Box>
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            marginTop: 5,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ textAlign: "center", padding: 2 }}
          >
            <Box
              sx={{
                backgroundColor: "#1E88E5",
                border: "2px solid #0A3F91",
                borderRadius: "50%",
                height: 100,
                width: 100,
                display: "block",
                marginX: "auto",
                color: "#FAFAFA",
              }}
            >
              <img
                src={integrity}
                alt="Integrity"
                style={{ height: 75, width: 75, padding: 10 }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: 20,
                marginTop: 2,
                color: "#4D4D4D",
                fontWeight: 600,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Trust
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#4D4D4D",
                fontSize: 14,
                marginTop: 1,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              At Techhabiles, trust is not just a word; it's a cornerstone of
              everything we do. We believe that trust forms the foundation of
              strong relationships, both within our team and with our clients
              and partners. <br />
              <br />
              <b>Externally</b>, trust means that our clients and partners can
              have confidence in our abilities, integrity, and commitment to
              delivering exceptional results. We prioritize open communication,
              listen attentively to their needs, and strive to exceed
              expectations in every interaction. We recognize that trust is
              earned through consistent actions and genuine care for the
              well-being of those we serve. <br />
              <br />
              <b>Internally</b>, trust means that every team member can rely on
              one another to do their best work, collaborate effectively, and
              support each other's growth and development. We cultivate an
              environment where transparency and honesty are valued, where
              everyone feels empowered to speak up, share ideas, and contribute
              to our collective success. <br />
              <br />
              Trust is not something we take for granted; it's something we work
              hard to build and maintain every day. By demonstrating
              reliability, accountability, and respect in all our endeavors, we
              aim to cultivate lasting relationships built on trust, laying the
              groundwork for mutual success and growth."
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ textAlign: "center", padding: 2 }}
          >
            <Box
              sx={{
                backgroundColor: "#1E88E5",
                border: "2px solid #0A3F91",
                borderRadius: "50%",
                height: 100,
                width: 100,
                display: "block",
                marginX: "auto",
                color: "#FAFAFA",
              }}
            >
              <img
                src={accountability}
                alt="Accountability"
                style={{ height: 75, width: 75, padding: 10 }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: 20,
                marginTop: 2,
                color: "#4D4D4D",
                fontWeight: 600,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Accountability
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#4D4D4D",
                fontSize: 14,
                marginTop: 1,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              At Techhabiles, we embrace accountability as a fundamental pillar
              of our culture. We believe that taking ownership of our actions,
              decisions, and outcomes is essential for personal and collective
              growth.
              <br />
              <br />
              <b>Externally</b>, accountability means being transparent and
              responsive to our clients, partners, and stakeholders. We
              acknowledge our responsibilities to them and strive to uphold the
              trust they've placed in us. If challenges arise, we proactively
              communicate, seek solutions, and take swift action to address any
              issues, demonstrating our unwavering commitment to delivering
              value and fostering long-term relationships.
              <br />
              <br />
              <b>Internally</b>, accountability means holding ourselves to high
              standards of performance and behavior. We take pride in our work
              and accept responsibility for delivering results that meet or
              exceed expectations. We honor our commitments and deadlines,
              recognizing that our individual contributions directly impact the
              success of the team and the company as a whole.
              <br />
              <br />
              We understand that accountability is not about assigning blame but
              rather about taking proactive steps to learn from our experiences
              and improve continuously. By holding ourselves and each other
              accountable, we create a culture of excellence where integrity,
              reliability, and commitment to excellence are the norm, driving
              our collective success and growth."
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ textAlign: "center", padding: 2 }}
          >
            <Box
              sx={{
                backgroundColor: "#1E88E5",
                border: "2px solid #0A3F91",
                borderRadius: "50%",
                height: 100,
                width: 100,
                display: "block",
                marginX: "auto",
                color: "#FAFAFA",
              }}
            >
              <img
                src={discipline}
                alt="Discipline"
                style={{
                  height: 75,
                  width: 75,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: 20,
                marginTop: 2,
                color: "#4D4D4D",
                fontWeight: 600,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Customer Commitment
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#4D4D4D",
                fontSize: 14,
                marginTop: 1,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              At Techhabiles, our customers are at the heart of everything we
              do. We are deeply committed to their success, satisfaction, and
              overall experience with our products and services.
              <br />
              <br />
              <b>Externally</b>, customer commitment means that we are
              accessible, responsive, and reliable partners. We view every
              interaction as an opportunity to build trust, nurture
              relationships, and demonstrate our unwavering dedication to their
              success. We prioritize clear communication, transparency, and
              integrity, ensuring that our customers feel valued, supported, and
              empowered throughout their journey with us.
              <br />
              <br />
              <b>Internally</b>, customer commitment means that every member of
              our team is dedicated to understanding and anticipating the needs
              of our customers. We prioritize active listening and empathy,
              striving to develop deep insights into their challenges, goals,
              and aspirations. We go above and beyond to deliver personalized
              solutions that address their unique requirements and exceed their
              expectations.
              <br />
              <br />
              We recognize that our customers' success is intertwined with our
              own, and we approach every engagement with a shared sense of
              purpose and accountability. By fostering a culture of
              customer-centricity and continuous improvement, we aim to not only
              meet but exceed our customers' evolving needs, driving mutual
              growth and long-term partnerships.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* People Matter */}
      <Box
        sx={{
          backgroundColor: "#EFF3F7",
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <img src={peoplematter} alt="PeopleMatter" height={50} />
        <Container>
          <Typography
            variant="body1"
            sx={{
              fontSize: 14,
              paddingLeft: 5,
              paddingRight: 5,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            At the core of our organization lies a vision where each member of
            our team is recognized as a vital contributor to our success. We aim
            to cultivate a workplace where diversity is celebrated, creativity
            is encouraged, and collaboration is inherent. We believe in the
            power of diversity. Our commitment to inclusivity extends to
            creating an environment where individuals from all backgrounds feel
            respected, included, and empowered to bring their authentic selves
            to work. We are committed to nurturing the growth and development of
            our employees. Through continuous learning initiatives, mentorship
            programs, and career development opportunities, we empower our team
            members to reach their full potential and pursue their professional
            goals. We believe in recognizing and celebrating the contributions
            of our employees. From formal recognition programs to spontaneous
            expressions of appreciation, we strive to create a culture of
            recognition where every achievement is acknowledged and valued. Open
            communication is essential to our culture. We provide various
            channels for feedback and dialogue, ensuring that every voice is
            heard and every opinion matters. Whether through regular team
            meetings, anonymous surveys, or one-on-one discussions, we
            prioritize transparent communication at all levels.
          </Typography>
          <Button
            onClick={() => navigate("/contactus")}
            sx={{
              display: "flex",
              fontFamily: "Poppins, sans-serif",
              marginLeft: "auto",
              marginRight: "auto",
              color: "#02388D",
              fontSize: 20,
              fontWeight: 600,
              border: "1px solid #02388D",
              textTransform: "unset",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            Contact Us <ChevronRightIcon />
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default Home;
