import React from "react";
import banner from "../Assests/Banner.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "750px",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          className="banner"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 30,
            paddingLeft: 20,
            color: "#FAFAFA",
          }}
        >
          {" "}
          <Typography variant="h3" sx={{ fontFamily: "Poppins, sans-serif" }}>
            WE <b>CREATE</b>
          </Typography>
          <Typography
            variant="h3"
            sx={{ marginTop: 1, fontFamily: "Poppins, sans-serif" }}
          >
            WHAT YOU NEED
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: 1,
              width: "50%",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
            dignissimos reprehenderit voluptas commodi laudantiums.
          </Typography>
          <Button
            onClick={() => navigate("/contactus")}
            className="contactus"
            size="large"
            color="inherit"
            sx={{
              marginTop: 5,
              textTransform: "unset",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Contact Us <ChevronRightIcon fontSize="small" />
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default Hero;
