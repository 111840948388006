import Logo from "../Assests/Logo.png";
import {
  Box,
  Grid,
  Divider,
  Container,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        color: "#FAFAFA",
        backgroundColor: "#02388D",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Grid container>
          <Grid item sm={12} md={4} lg={4} sx={{ marginTop: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 75,
                  height: 75,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ height: 75, width: 75 }}
                  />
                </Link>
              </Box>
              <Box
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  align="center"
                  variant="body1"
                  sx={{
                    marginTop: 2,
                    marginLeft: 2,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  WE <b>CREATE</b> WHAT YOU NEED
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <IconButton sx={{ color: "#FAFAFA" }}>
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton sx={{ color: "#FAFAFA" }}>
                    <FacebookIcon />
                  </IconButton>
                  <IconButton sx={{ color: "#FAFAFA" }}>
                    <InstagramIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={8}
            lg={8}
            sx={{ display: "flex", alignItems: "space-around" }}
          >
            <Box sx={{ margin: 5 }}>
              {" "}
              <Button
                sx={{
                  textTransform: "unset",
                  color: "#FAFAFA",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={() => navigate("/services")}
              >
                Services
              </Button>
            </Box>

            <Box sx={{ margin: 5 }}>
              {" "}
              <Button
                sx={{
                  textTransform: "unset",
                  color: "#FAFAFA",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={() => navigate("/technologies")}
              >
                Technologies
              </Button>
            </Box>

            <Box sx={{ margin: 5 }}>
              <Button
                sx={{
                  textTransform: "unset",
                  color: "#FAFAFA",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={() => navigate("/aboutus")}
              >
                About Us
              </Button>
            </Box>

            <Box sx={{ margin: 5 }}>
              <Button
                sx={{
                  textTransform: "unset",
                  color: "#FAFAFA",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={() => navigate("/contactus")}
              >
                Contact Us
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ backgroundColor: "#FAFAFA" }} />

      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" sx={{ fontFamily: "Poppins, sans-serif" }}>
          &copy;Copyright 2024, All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
