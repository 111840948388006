import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import sidepolygon from "../Assests/contactus/sidepolygon.svg";
import frame from "../Assests/contactus/frame.svg";
import laptop from "../Assests/contactus/laptop.svg";
import { motion } from "framer-motion";

const handleSubmit = (e) => {
  e.preventDefault();
};
function ContactUs() {
  return (
    <motion.div
      className="services"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } }}
    >
      <Box
        sx={{
          backgroundImage: `url(${frame})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            lg={2}
            md={3}
            sx={{
              display: { sm: "none", xs: "none", md: "block", lg: "block" },
              backgroundImage: `url(${sidepolygon})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "100vh",
              width: "auto",
            }}
          ></Grid>
          <Grid item sx={{ marginTop: 15 }} lg={10} md={9}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#393939",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 600,
                  fontSize: 32,
                }}
              >
                Contact Us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#7C7C7C",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                  fontSize: 18,
                  marginTop: 1,
                }}
              >
                Got a Project in Mind? Let us help you achieve your Vision!
              </Typography>
            </Box>
            <Grid container spacing={3} sx={{ margin: 5 }}>
              <Grid item>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#393939",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    Connect with Our Team
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#7C7C7C",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      fontSize: 14,
                    }}
                  >
                    Contact our team directly to bring you closer to your goal.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "space-evenly",
                      marginTop: 3,
                      marginBottom: 4,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        display: "flex",
                        color: "#4D4D4D",
                        marginRight: 2,
                      }}
                    >
                      <PhoneOutlinedIcon
                        fontSize="small"
                        sx={{ color: "#02388D", padding: 0.5, marginRight: 1 }}
                      />
                      +91 9971103052
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        display: "flex",
                        color: "#4D4D4D",
                        marginLeft: 2,
                      }}
                    >
                      <MailOutlineIcon
                        fontSize="small"
                        sx={{ color: "#02388D", padding: 0.5, marginRight: 1 }}
                      />{" "}
                      care@techhabiles.com
                    </Typography>
                  </Box>
                  <img src={laptop} alt="laptop" width="475" loading="lazy" />
                </Box>
              </Grid>
              <Grid item>
                <Paper
                  sx={{
                    backgroundColor: "#EFF3F7",
                    border: "1px solid #ADADAD",
                    borderRadius: "30px",
                    width: 500,
                    height: "auto",
                    marginLeft: 5,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: 4,
                      marginBottom: 4,
                      gap: 2,
                    }}
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="First and Last Name"
                      type="text"
                      size="small"
                      required
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 12,
                        width: "75%",
                        border: "2px solid #1B1B1B",
                        borderRadius: "10px",
                        backgroundColor: "#FBFBFB",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "transparent",
                          },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                          paddingLeft: "20px",
                        },
                      }}
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Email"
                      type="email"
                      size="small"
                      required
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 12,
                        width: "75%",
                        border: "2px solid #1B1B1B",
                        borderRadius: "10px",
                        backgroundColor: "#FBFBFB",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "transparent",
                          },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                          paddingLeft: "20px",
                        },
                      }}
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Phone Number"
                      size="small"
                      required
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 12,
                        width: "75%",
                        border: "2px solid #1B1B1B",
                        borderRadius: "10px",
                        backgroundColor: "#FBFBFB",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "transparent",
                          },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                          paddingLeft: "20px",
                        },
                      }}
                    />
                    <TextField
                      multiline
                      rows={2}
                      variant="outlined"
                      placeholder="Your Message"
                      size="small"
                      required
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 12,
                        width: "75%",
                        border: "2px solid #1B1B1B",
                        borderRadius: "10px",
                        backgroundColor: "#FBFBFB",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "transparent",
                          },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                        },
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        width: "75%",
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "unset",
                        backgroundColor: "#02388D",
                        borderRadius: "20px",
                      }}
                    >
                      Submit
                    </Button>
                    <Typography
                      align="center"
                      variant="caption"
                      sx={{
                        color: "#4D4D4D",
                        width: "75%",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 700,
                      }}
                    >
                      By clicking the ‘Submit’ button, you agree that we may
                      contact you by phone, email, or text message.
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
}

export default ContactUs;
