import { Box, Button, Container, Grid, Typography } from "@mui/material";
import ourservices from "../Assests/services/ourservices.svg";
import lightblue from "../Assests/aboutus/ourprocess/lightblue.svg";
import service1 from "../Assests/services/icons/service1.svg";
import service2 from "../Assests/services/icons/service2.svg";
import service3 from "../Assests/services/icons/service3.svg";
import service4 from "../Assests/services/icons/service4.svg";
import service5 from "../Assests/services/icons/service5.svg";
import service6 from "../Assests/services/icons/service6.svg";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { motion } from "framer-motion";

function Services() {
  const ourServices = [
    {
      icon: service1,
      title: "Devops",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo blanditiis accusamus ex tenetur maxime voluptas nostrum, provident numquam sapiente, porro recusandae dolore libero alias, repudiandae unde aliquam magnam reprehenderit soluta!",
    },
    {
      icon: service3,
      title: "⁠Cloud Infra Management",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo blanditiis accusamus ex tenetur maxime voluptas nostrum, provident numquam sapiente, porro recusandae dolore libero alias, repudiandae unde aliquam magnam reprehenderit soluta!",
    },
    {
      icon: service6,
      title: "Mobile Development ",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo blanditiis accusamus ex tenetur maxime voluptas nostrum, provident numquam sapiente, porro recusandae dolore libero alias, repudiandae unde aliquam magnam reprehenderit soluta!",
    },
    {
      icon: service5,
      title: "Web Development",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo blanditiis accusamus ex tenetur maxime voluptas nostrum, provident numquam sapiente, porro recusandae dolore libero alias, repudiandae unde aliquam magnam reprehenderit soluta!",
    },
    {
      icon: service2,
      title: "Rest API Development",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo blanditiis accusamus ex tenetur maxime voluptas nostrum, provident numquam sapiente, porro recusandae dolore libero alias, repudiandae unde aliquam magnam reprehenderit soluta!",
    },
    {
      icon: service4,
      title: "UI Design",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo blanditiis accusamus ex tenetur maxime voluptas nostrum, provident numquam sapiente, porro recusandae dolore libero alias, repudiandae unde aliquam magnam reprehenderit soluta!",
    },
  ];

  return (
    <motion.div
      className="services"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } }}
    >
      <Typography
        align="center"
        variant="h5"
        sx={{
          color: "#393939",
          fontSize: 28,
          fontFamily: "Poppins, sans-serif",
          fontWeight: 600,
          marginTop: 15,
        }}
      >
        Our Services
      </Typography>
      <Typography
        align="center"
        variant="subtitle1"
        sx={{
          color: "#7C7C7C",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 500,
          marginBottom: 5,
        }}
      >
        Have a look at the Services we Provide
      </Typography>
      <img src={ourservices} style={{ width: "100%" }} loading="lazy" />
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ marginBottom: 5 }}
        >
          {ourServices.map((service, index) => (
            <Grid item key={index} sm={12} md={6} lg={4}>
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  position: "relative",
                  borderRadius: 2,
                  overflow: "hidden",
                  top: "-150px",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  boxShadow: "0px 5px 0px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box
                  sx={{
                    height: 75,
                    width: 75,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    top: 30,
                    left: { xs: "45%", lg: "40%" },
                    borderRadius: "20px",
                    backgroundColor: "#02388D",
                    border: "2px solid #02388D",
                  }}
                >
                  <img
                    src={service.icon}
                    alt={`Icon ${index + 1}`}
                    height={30}
                    style={{ padding: 10 }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    backgroundImage: `url(${lightblue})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#4D4D4D",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    borderRadius: "5px",
                    paddingTop: 5,
                  }}
                >
                  {service.title}
                </Typography>
                <Container
                  sx={{
                    backgroundImage: `url(${lightblue})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    paddingBottom: 5,
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      color: "#7C7C7C",
                      padding: 3,
                      fontWeight: 500,
                      fontSize: 14,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {service.description}
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: "12px",
                      display: "flex",
                      border: "2px solid #02388D",
                      color: "#02388D",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 12,
                      marginLeft: "auto",
                      marginRight: "auto",
                      "&:hover": {
                        backgroundColor: "#02388D",
                        color: "white",
                      },
                    }}
                  >
                    Read more <ChevronRight />
                  </Button>
                </Container>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
}

export default Services;
