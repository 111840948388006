// import React, { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// import Loading from "./Loading";
import { AnimatePresence } from "framer-motion";

// const Main = lazy(() => import("../Pages/Main"));
// const AboutUs = lazy(() => import("../Pages/AboutUs"));
// const Services = lazy(() => import("../Pages/Services"));
// const ContactUs = lazy(() => import("../Pages/ContactUs"));
// const Technologies = lazy(() => import("../Pages/Technologies"));

/* To view only effect */
import Main from "../Pages/Main";
import Services from "../Pages/Services";
import Technologies from "../Pages/Technologies";
import AboutUs from "../Pages/AboutUs";
import ContactUs from "../Pages/ContactUs";
import ScrollToTop from "../Components/ScrollToTop";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <>
      {/* <Suspense fallback={<Loading />}> */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Main />} />
          <Route path="/services" element={<Services />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
        <ScrollToTop />
      </AnimatePresence>
      {/* </Suspense> */}
    </>
  );
}

export default AnimatedRoutes;
