import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../Assests/Logo.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "#005EB8",
      }}
    >
      <Toolbar>
        <Box
          style={{
            backgroundColor: "#FAFAFA",
            borderRadius: "50%",
          }}
        >
          <Link to="/">
            <img src={Logo} alt="Logo" style={{ height: 50, width: 55 }} />
          </Link>
        </Box>

        <Box style={{ marginLeft: "auto" }}>
          <Button
            sx={{
              textTransform: "unset",
              color: "#FAFAFA",
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={() => navigate("/services")}
          >
            Services
          </Button>
          <Button
            sx={{
              textTransform: "unset",
              color: "#FAFAFA",
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={() => navigate("/technologies")}
          >
            Technologies
          </Button>

          <Button
            sx={{
              textTransform: "unset",
              color: "#FAFAFA",
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Poppins, sans-serif",
            }}
            onClick={() => navigate("/aboutus")}
          >
            About Us
          </Button>
          <Button
            onClick={() => navigate("/contactus")}
            sx={{
              backgroundColor: "#EDF4FF",
              fontFamily: "Poppins, sans-serif",
              color: "#025BCF",
              fontWeight: 600,
              padding: 1,
              border: "1px solid #025BCF",
              borderRadius: 2,
              marginLeft: 5,
              textTransform: "unset",
              "&:hover": {
                border: "1px solid #025BCF",
                backgroundColor: "#EDF4FF",
                color: "#025BCF",
              },
            }}
          >
            Contact Us <ChevronRightIcon fontSize="small" />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
