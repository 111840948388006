import { Box, CardContent, Container, Grid, Typography } from "@mui/material";
import ourstory from "../Assests/aboutus/ourstory.svg";
import icon1 from "../Assests/aboutus/ourprocess/icons/icon1.svg";
import icon2 from "../Assests/aboutus/ourprocess/icons/icon2.svg";
import icon3 from "../Assests/aboutus/ourprocess/icons/icon3.svg";
import icon4 from "../Assests/aboutus/ourprocess/icons/icon4.svg";
import lightblue from "../Assests/aboutus/ourprocess/lightblue.svg";
import { motion } from "framer-motion";

function AboutUs() {
  const ourProcess = [
    {
      icon: icon2,
      title: "Project Initiation",
      listItems: ["Proposal", "Project Plan", "Solution Page"],
    },
    {
      icon: icon1,
      title: "Design",
      listItems: ["Concept ", "Branding", "Mockups"],
    },
    {
      icon: icon4,
      title: "Development ",
      listItems: ["Proposal", "Project Plan", "Solution Page"],
    },
    {
      icon: icon3,
      title: "Support",
      listItems: ["Proposal", "Project Plan", "Solution Page"],
    },
  ];
  return (
    <motion.div
      className="services"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } }}
    >
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Stats */}
        <Box>
          <Typography
            align="center"
            variant="h5"
            sx={{
              color: "#393939",
              fontSize: 28,
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              marginTop: 15,
            }}
          >
            About Us
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            sx={{
              color: "#7C7C7C",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
            }}
          >
            Let us tell you something About Us!
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 1 }}
          >
            <Grid item>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 28,
                  color: "#7C7C7C",
                  marginRight: 10,
                }}
              >
                50 +
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 14,
                  color: "#4D4D4D",
                  marginRight: 10,
                }}
              >
                PROJECTS
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 28,
                  color: "#7C7C7C",
                  marginLeft: 5,
                }}
              >
                30
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 14,
                  color: "#4D4D4D",
                  marginLeft: 5,
                }}
              >
                TEAM MEMBERS
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 28,
                  color: "#7C7C7C",
                  marginLeft: 10,
                }}
              >
                80 +
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: 14,
                  color: "#4D4D4D",
                  marginLeft: 10,
                }}
              >
                HAPPY CLIENTS
              </Typography>
            </Grid>
          </Grid>
          <Typography
            align="center"
            variant="h6"
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              marginTop: 5,
              marginBottom: 3,
            }}
          >
            Our Story
          </Typography>
        </Box>

        {/* Our Story */}
        <img src={ourstory} style={{ width: "100%" }} loading="lazy" />

        {/* Body content */}
        <Container sx={{ marginTop: 5 }}>
          {" "}
          <Typography
            align="justify"
            variant="body1"
            sx={{
              color: "#4D4D4D",
              fontFamily: "Poppins, sans-serif",
              marginTop: 3,
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua.
          </Typography>
          <Typography
            align="justify"
            variant="body1"
            sx={{
              color: "#4D4D4D",
              fontFamily: "Poppins, sans-serif",
              marginTop: 2,
            }}
          >
            {" "}
            Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </Typography>{" "}
          <Typography
            align="justify"
            variant="body1"
            sx={{
              color: "#4D4D4D",
              fontFamily: "Poppins, sans-serif",
              marginTop: 2,
            }}
          >
            {" "}
            Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.{" "}
          </Typography>
        </Container>

        {/* What's different */}
        <Box
          sx={{
            backgroundColor: "#EFF3F7",
            display: "flex",
            flexDirection: "column",
            marginTop: 5,
          }}
        >
          <Typography
            align="center"
            variant="h6"
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#393939",
              fontWeight: 600,
              marginTop: 3,
            }}
          >
            What’s Different when You Work with Us?
          </Typography>
          <Grid spacing={1} sx={{ display: "flex" }}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Container
                sx={{ paddingLeft: 5, paddingBottom: 5, width: "90%" }}
              >
                {" "}
                <Typography
                  align="justify"
                  variant="body1"
                  sx={{
                    color: "#4D4D4D",
                    fontSize: 14,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 3,
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris
                </Typography>
                <Typography
                  align="justify"
                  variant="body1"
                  sx={{
                    color: "#4D4D4D",
                    fontSize: 14,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 3,
                  }}
                >
                  {" "}
                  Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut
                </Typography>{" "}
                <Typography
                  align="justify"
                  variant="body1"
                  sx={{
                    color: "#4D4D4D",
                    fontSize: 14,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 3,
                  }}
                >
                  {" "}
                  Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.{" "}
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Container
                sx={{ paddingLeft: 5, paddingBottom: 5, width: "90%" }}
              >
                {" "}
                <Typography
                  align="justify"
                  variant="body1"
                  sx={{
                    color: "#4D4D4D",
                    fontSize: 14,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 3,
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris
                </Typography>
                <Typography
                  align="justify"
                  variant="body1"
                  sx={{
                    color: "#4D4D4D",
                    fontSize: 14,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: 3,
                  }}
                >
                  {" "}
                  <li>
                    Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Ut enim ad minim veniam,
                    quis nostrud Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit.
                  </li>{" "}
                  <li>
                    Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Ut enim ad minim veniam,
                    quis nostrud Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit.
                  </li>{" "}
                  <li>
                    Ut enim ad minim veniam, quis nostrud Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Ut enim ad minim veniam,
                    quis nostrud Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit.
                  </li>{" "}
                </Typography>{" "}
              </Container>
            </Grid>
          </Grid>
        </Box>

        {/* Our Process */}
        <Box>
          <Typography
            align="center"
            variant="h5"
            sx={{
              color: "#393939",
              fontSize: 24,
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              marginTop: 5,
            }}
          >
            Our Process
          </Typography>
          <Typography
            align="center"
            variant="subtitle1"
            sx={{
              color: "#7C7C7C",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
            }}
          >
            At techHabiles, we follow an iterative process to ensure you achieve
            your dream.
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ marginBottom: 5 }}
          >
            {ourProcess.map((process, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    width: 250,
                    height: "auto",
                    position: "relative",
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  }}
                >
                  <Box
                    sx={{
                      height: 60,
                      width: 60,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      top: 30,
                      left: "35%",
                      borderRadius: "50%",
                      backgroundColor: "#1E88E5",
                      border: "2px solid #02388D",
                    }}
                  >
                    <img
                      src={process.icon}
                      alt={`Icon ${index + 1}`}
                      height={30}
                      style={{ padding: 10 }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      backgroundColor: "#02388D",
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 700,
                      borderRadius: "5px",
                      paddingY: 5,
                    }}
                  >
                    {process.title}
                  </Typography>
                  <CardContent
                    sx={{
                      backgroundImage: `url(${lightblue})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      padding: 2,
                    }}
                  >
                    {process.listItems.map((item, i) => (
                      <Typography
                        key={i}
                        variant="body1"
                        component="div"
                        sx={{
                          color: "#7C7C7C",
                          fontWeight: 500,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <li>{item}</li>
                      </Typography>
                    ))}
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </motion.div>
  );
}

export default AboutUs;
