import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import technologies from "../Assests/technologies/technologies.svg";
import AWS from "../Assests/technologies/images/AWS.svg";
import Networking from "../Assests/technologies/images/Networking.svg";
import ANSIBLE from "../Assests/technologies/images/ANSIBLE.svg";
import Kubernetes from "../Assests/technologies/images/Kubernetes.svg";
import Android from "../Assests/technologies/images/Android.svg";
import ReactNative from "../Assests/technologies/images/ReactNative.svg";
import ReactJs from "../Assests/technologies/images/ReactJs.svg";
import Virtualisation from "../Assests/technologies/images/Virtualisation.svg";
import CICD from "../Assests/technologies/images/CICD.svg";
import Docker from "../Assests/technologies/images/Docker.svg";
import Terraform from "../Assests/technologies/images/Terraform.svg";
import iOS from "../Assests/technologies/images/iOS.svg";
import Spring from "../Assests/technologies/images/Spring.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { motion } from "framer-motion";

const Ourtechnologies = [
  {
    image: AWS,
    title: "AWS",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: Virtualisation,
    title: "Virtualisation",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },

  {
    image: Networking,
    title: "Networking & Server Management",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: CICD,
    title: "⁠GIT, JENKINS, CI/CD pipelines",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: ANSIBLE,
    title: "ANSIBLE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: Docker,
    title: "⁠Docker",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: Kubernetes,
    title: "Kubernetes",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: Terraform,
    title: "Terraform",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: Android,
    title: "Android",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: iOS,
    title: "iOS",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: ReactNative,
    title: "React Native",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },

  {
    image: Spring,
    title: "⁠Spring, Spring Boot, Microservices",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
  {
    image: ReactJs,
    title: "ReactJs",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. t amet, consectetur adipiscing ",
  },
];
function Technologies() {
  return (
    <motion.div
      className="services"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } }}
    >
      {" "}
      <Typography
        align="center"
        variant="h5"
        sx={{
          color: "#393939",
          fontSize: 28,
          fontFamily: "Poppins, sans-serif",
          fontWeight: 600,
          marginTop: 15,
        }}
      >
        Technologies
      </Typography>
      <Typography
        align="center"
        variant="subtitle1"
        sx={{
          color: "#7C7C7C",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 500,
          marginBottom: 10,
        }}
      >
        Have a look at the Technologies we Work with
      </Typography>
      <img
        src={technologies}
        alt="Technologies"
        style={{ width: "100%" }}
        loading="lazy"
      />
      <Container
        sx={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <Grid container>
          <Grid item container rowGap={3}>
            {Ourtechnologies.map((item, index) =>
              index % 2 ? (
                <>
                  <Grid item lg={2}>
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        height: "200px",
                        width: "auto",
                      }}
                    />
                  </Grid>
                  <Grid item lg={10}>
                    <Paper
                      elevation={2}
                      sx={{
                        padding: 5,
                        marginLeft: 1,
                        borderRadius: "28px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 2px 16px 0px #00000033",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#4D4D4D",
                          marginBottom: 1,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: 13,
                          color: "#4D4D4D",
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: "unset",
                          border: "2px solid #02388D",
                          color: "#02388D",
                          borderRadius: "12px",
                          fontFamily: "Poppins, sans-serif",
                          float: "right",
                          marginBottom: 2,
                        }}
                      >
                        Read More{" "}
                        <ChevronRightIcon
                          fontSize="small"
                          sx={{ color: "#02388D" }}
                        />
                      </Button>
                    </Paper>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={10}>
                    <Paper
                      elevation={2}
                      sx={{
                        padding: 5,
                        marginRight: 1,
                        borderRadius: "28px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 2px 16px 0px #00000033",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#4D4D4D",
                          marginBottom: 1,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: 13,
                          color: "#4D4D4D",
                        }}
                      >
                        {item.description}
                      </Typography>

                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: "unset",
                          border: "2px solid #02388D",
                          color: "#02388D",
                          borderRadius: "12px",
                          fontFamily: "Poppins, sans-serif",
                          float: "right",
                          marginBottom: 2,
                        }}
                      >
                        Read More{" "}
                        <ChevronRightIcon
                          fontSize="small"
                          sx={{ color: "#02388D" }}
                        />
                      </Button>
                    </Paper>
                  </Grid>
                  <Grid item lg={2} sx={{ textAlign: "right" }}>
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        width: "auto",
                        height: "200px",
                      }}
                    />
                  </Grid>
                </>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}

export default Technologies;
